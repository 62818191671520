import React, { Component } from 'react'
import { graphql } from 'gatsby'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import Layout from 'Components/Layout'
import SeoHead from 'Components/SeoHead'
import Breadcrumbs from 'Components/UI/Breadcrumbs'
import uiManager from 'Components/Utils/uiManager'
import ElementsObserver from 'Utils/ElementsObserver'

class EditorialPage extends Component {
  constructor(props) {
    super(props)
    const { components } = props.data.allEditorialJson.edges[0].node
    this.components = components.map((component) => {
      return {
        type: component.type,
        params: component.params
      }
    })
    this.breadcrumbsData = [
      {
        path: `${this.props.location.pathname}`,
        title: 'Fall-Winter 2018'
      }
    ]
    this.uiManager = new uiManager(this.components)
  }
  componentDidMount() {
    clearTimeout(this.observerTimeout)
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentDidUpdate() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentWillUnmount() {
    if(this.observer) this.observer.destroy()
  }
  createObserver = () => {
    this.observer = new ElementsObserver({
      entries: document.querySelectorAll('video'),
      onEnter: entry => {
        const playPromise = entry.play()
        if (playPromise !== undefined) {
          playPromise.then(_ => {
          }).catch(error => {
          })
        }
      },
      onExit: entry => {
        entry.pause()
      }
    })
  }
  render() {
    const components = this.uiManager.renderComponents()
    return (
      <Layout>
        <SeoHead
          title="Fall-Winter 2018"
          path={this.props.location.pathname}
        />
        <Breadcrumbs items={this.breadcrumbsData}/>
        { components }
      </Layout>
    )
  }
}

export const EditorialQuery = graphql`
query EditorialQuery {
  allEditorialJson(limit: 20) {
    edges {
      node {
        components {
          type
          params {
            type
            urlRetina
            title
            text
            position
            height
            center
            border
            extraClasses
            fitToContent
            items {
              type
              urlRetina
              classes
              border
            }
            slidesOne {
              type
              media {
                rect {
                  src
                }
              }
            }
            slidesTwo {
              type
              url
              media {
                rect {
                  src
                  srcRetina
                }
              }
            }
            button {
              text
              url
            }
          }
        }
      }
    }
  }
}
`

export default EditorialPage
